@charset 'utf-8';

@import 'settings';
@import 'components/layout_helpers';

h1,
h2,
h3,
h4,
h5 {
  color: inherit;
  font-weight: 600;
}

p {
  color: inherit;
}

a,
a img,
.button.button--arrow {
  transition: filter 0.25s ease-out;
}

a:hover,
a:hover img,
.button.button--arrow:hover {
  filter: contrast(0.85);
}

.is-black {
  color: $c-black;
}

.is-bg-black,
.is-bg-black.button {
  background-color: $c-black;
  color: $c-white !important;
}

.is-bg-blue,
.is-bg-blue.button {
  background-color: $c-blue;
  color: $c-white !important;
}

.is-bg-purple,
.is-bg-purple.button {
  background-color: $c-purple;
  color: $c-white !important;
}

.is-bg-aqua,
.is-bg-aqua.button {
  background-color: $c-aqua;
  color: $c-white !important;
}

.is-bg-blue-light {
  background-color: $c-light-blue;
}

.is-bg-green {
  background-color: $c-green;
}

.is-bg-aqua-light {
  background-color: $c-light-aqua;
}

.item-label {
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
}

.button.button--arrow {
  height: 50px;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 50px;
  max-width: 250px;
  padding: 0;
  position: relative;
  width: 100%;

  &::after {
    border: 0;
    content: '\2192';
    right: -40px;
    height: 50px;
    top: -1px;
    width: 50px;
    line-height: 50px;
    position: absolute;
  }

  &.is-bg-blue::after {
    background: $c-grey;
    color: $c-blue;
  }

  &.is-bg-aqua::after {
    background: $c-white;
    color: $c-aqua;
  }

  &.is-bg-purple::after {
    background: $c-grey;
    color: $c-purple;
  }
}

.more {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;

  &::before {
    content: '\2192  ';
    font-size: 1.3em;
  }
}

.boxes {
  display: block;
}

a.box {
  color: inherit;
  cursor: pointer;
}

.box {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  display: inline-block;
  position: relative;
  transition: box-shadow 0.25s ease-out;
  padding: 0 1rem 4rem;

  &:hover {
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4);
  }

  .more {
    display: inline-block;
    position: absolute;
    bottom: 1em;
  }
}

@media screen and #{breakpoint(medium up)} {
  .boxes {
    display: flex;
    justify-content: space-between;

    &.boxes--3 .box {
      width: calc((100% / 3) - 2rem);
    }
  }
}
